import '/src/css/style.pcss'

import barba from '@barba/core';
import { gsap } from "gsap";

window.gsap = gsap;

barba.init({
  preventRunning: true,
  transitions: [{
    name: 'to-detail',
    sync: false,
    
    from: {
      namespace: ['competitie']
    },
    to: {
      namespace: [
        'competitie/wedstrijden',
        'competitie/spelers'
      ]
    },
    
    async leave(data) {
      const done = this.async();
      
      await gsap.fromTo(data.current.container.querySelector('.main'),
        { opacity: 1, x: 0 },
        {
          duration: 0.3,
          opacity: 0,
          x: -100,
          ease: "power2.inOut"
        }
      );
      
      done();
    },
    async after(data) {
      const done = this.async();
      
      await gsap.fromTo(data.next.container.querySelector('.main'),
        { opacity: 0, x: 100 },
        {
          duration: 0.3,
          opacity: 1,
          x: 0,
          ease: "power2.inOut"
        }
      );
      
      done();
    }
  },
  {
    name: 'back-to-main',
    sync: false,
    
    from: {
      namespace: [
        'competitie/wedstrijden',
        'competitie/spelers'
      ]
    },
    to: {
      namespace: ['competitie']
    },
    
    async leave(data) {
      const done = this.async();
      
      await gsap.fromTo(data.current.container.querySelector('.main'),
        { opacity: 1, x: 0 },
        {
          duration: 0.3,
          opacity: 0,
          x: 100,
          ease: "power2.inOut"
        }
      );
      
      done();
    },
    async after(data) {
      const done = this.async();
      
      await gsap.fromTo(data.next.container.querySelector('.main'),
        { opacity: 0, x: -100 },
        {
          duration: 0.3,
          opacity: 1,
          x: 0,
          ease: "power2.inOut"
        }
      );
      
      done();
    }
  },
  {
    name: 'wedstrijden-to-spelers',
    sync: false,
    
    from: {
      namespace: ['competitie/wedstrijden']
    },
    to: {
      namespace: ['competitie/spelers']
    },
    
    async leave(data) {
      const done = this.async();
      
      await gsap.fromTo(data.current.container.querySelector('.main'),
        { opacity: 1, x: 0 },
        {
          duration: 0.3,
          opacity: 0,
          x: -100,
          ease: "power2.inOut"
        }
      );
      
      done();
    },
    async after(data) {
      const done = this.async();
      
      await gsap.fromTo(data.next.container.querySelector('.main'),
        { opacity: 0, x: 100 },
        {
          duration: 0.3,
          opacity: 1,
          x: 0,
          ease: "power2.inOut"
        }
      );
      
      done();
    }
  },
  {
    name: 'spelers-to-wedstrijden',
    sync: false,
    
    from: {
      namespace: ['competitie/spelers']
    },
    to: {
      namespace: ['competitie/wedstrijden']
    },
    
    async leave(data) {
      const done = this.async();
      
      await gsap.fromTo(data.current.container.querySelector('.main'),
        { opacity: 1, x: 0 },
        {
          duration: 0.3,
          opacity: 0,
          x: 100,
          ease: "power2.inOut"
        }
      );
      
      done();
    },
    async after(data) {
      const done = this.async();
      
      await gsap.fromTo(data.next.container.querySelector('.main'),
        { opacity: 0, x: -100 },
        {
          duration: 0.3,
          opacity: 1,
          x: 0,
          ease: "power2.inOut"
        }
      );
      
      done();
    }
  }]
});

tsParticles
    .loadJSON("tsparticles", "/particlesjs-config.json")
    .then(container => {
        console.log("callback - tsparticles config loaded");
    })
    .catch(error => {
        console.error(error);
    });

/**
 * Alpine configuration
**/
import Alpine from 'alpinejs'

// Alpine start
Alpine.start();

const logoWrapper = document.getElementById('logo-wrapper');
setInterval(() => {
  logoWrapper.classList.add('logo-wink');
  setTimeout(() => {
      logoWrapper.classList.remove('logo-wink');
  }, 1000);
}, 6000);

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
**/ 
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
  }
